import { BindParams, RootStore } from '@/components/DonationForm/types';
import { ComponentIds as Ids } from '@/components/DonationForm/constants';
import { makeAutoObservable } from 'mobx';
import { frequenciesToOptions } from '@/common/utils/frequenciesToOptions';

const bindFrequency = (rootStore: RootStore): BindParams => {
  const { $state } = rootStore;

  const frequencyState = makeAutoObservable({
    get options() {
      if ($state.isFrequencyEnabled) {
        return $state.campaign!.recurringDonation!.frequencies!.map((elem) =>
          frequenciesToOptions(elem, rootStore.localeKeys),
        );
      }
      return [];
    },
  });

  return {
    [Ids.Frequency]: {
      options: () => frequencyState.options,
      defaultValue: () => $state.frequencyDefaultValue,
    },
  };
};

export default bindFrequency;
