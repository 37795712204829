import { SPECS } from '@/common/constants/specs';
import { getCampaign } from '@/common/api/getCampaign';
import { RootStore } from '@/components/DonationForm/types';
import { DonationCampaign } from '@wix/ambassador-donations-v1-donation-campaign/types';
import { getIsPremium } from '@/common/api/getIsPremium';
import { getDefaultCampaign } from '@/common/utils/defaultCampaign';

export const getInitialData = async ({
  httpClient,
  experiments,
  $state,
}: RootStore) => {
  let campaignPromise: Promise<DonationCampaign | undefined> | undefined;
  const { campaignId } = $state;
  if (campaignId !== undefined) {
    campaignPromise = getCampaign(httpClient, campaignId);
  } else if (experiments.enabled(SPECS.defaultCampaign)) {
    campaignPromise = getDefaultCampaign(httpClient);
  }
  const [campaign, isPremium] = await Promise.all([
    campaignPromise,
    getIsPremium(httpClient),
  ]);
  return {
    campaign,
    isPremium,
  };
};
