import { ECOM_APP_DEF_ID } from '@/common/constants/app';
import { IWixAPI } from '@wix/yoshi-flow-editor';
import { thankYouPagePageName } from '@/editor-app/editor-ready/first-install/addthankYouPage';

export const navigateToCheckout = async (
  { wixCodeApi }: { wixCodeApi: IWixAPI },
  checkoutId: string,
  campaignId: string,
) => {
  const ecomPublicApi = await wixCodeApi.site.getPublicAPI(ECOM_APP_DEF_ID);
  await ecomPublicApi.navigate.toCheckout({
    checkoutId,
    disableContinueShopping: true,
    theme: 'donations',
    successUrl: `${wixCodeApi.location.baseUrl}/${thankYouPagePageName}/?orderid={orderId}%26campaignid=${campaignId}%26objecttype={objectType}`,
  });
};
