import { getDonationCampaign } from '@wix/ambassador-donations-v1-donation-campaign/http';
import { DonationCampaign } from '@wix/ambassador-donations-v1-donation-campaign/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export const getCampaign = async (
  httpClient: IHttpClient,
  campaignId: string,
): Promise<DonationCampaign | undefined> => {
  return httpClient
    .request(getDonationCampaign({ donationCampaignId: campaignId }))
    .then((res) => res.data.donationCampaign)
    .catch((e) => {
      console.error(`Failed to get campaign with id "${campaignId}"`, e);
      return undefined;
    });
};
