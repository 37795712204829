import { initLocaleKeys } from '@/common/utils/locale';
import model from './model';
import { RootStore } from './types';
import { getInitialData } from './services/getInitialData';
import { RootState } from './services/RootState';
import DonateButton from '@/components/DonationForm/DonateButton/DonateButton.bind';
import Frequency from '@/components/DonationForm/Frequency/Frequency.bind';
import Amount from '@/components/DonationForm/Amount/Amount.bind';
import EmptyState from '@/components/DonationForm/Empty/EmptyState.bind';
import Comment from '@/components/DonationForm/Comment/Comment.bind';
import { ComponentIds, StateIds } from './constants';
import { reportWidgetLoad } from '@/components/DonationForm/services/bi';
import { getTranslationOrUserText } from '@/common/utils/getTranslationOrUserText';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ILocaleKeys } from '@/locale-keys';

export default model.createController((controllerParams) => {
  const localeKeys = initLocaleKeys(controllerParams.flowAPI.translations.i18n);
  return {
    pageReady: async () => {
      const { $props, $w, $bindAll, flowAPI, controllerConfig, $widget } =
        controllerParams;
      const rootStore = {
        controllerParams,
        httpClient: flowAPI.essentials.httpClient,
        fedops: flowAPI.fedops,
        $w,
        experiments: flowAPI.experiments,
        wixCodeApi: controllerConfig.wixCodeApi,
        localeKeys: initLocaleKeys(flowAPI.translations.i18n),
        biLogger: flowAPI.bi,
      } as RootStore;
      rootStore.$state = new RootState(rootStore, $props, $widget.props);

      const setInitialStateData = async () => {
        const { campaign, isPremium } = await getInitialData(rootStore);

        if (campaign) {
          reportWidgetLoad($w, flowAPI, campaign);
          rootStore.$state.reset(
            campaign,
            flowAPI.environment.isViewer,
            isPremium,
          );
        }
      };
      controllerParams.$widget.onPropsChanged(setInitialStateData);
      await setInitialStateData();

      $bindAll({
        ...DonateButton(rootStore),
        ...Frequency(rootStore),
        ...Amount(rootStore),
        ...EmptyState(rootStore),
        ...Comment(rootStore),
        [ComponentIds.Title]: {
          text: () =>
            getTranslationOrUserText(
              $w(ComponentIds.Title).text,
              localeKeys.donationForm.title.defaultText(),
            ),
        },
        [ComponentIds.Description]: {
          text: () =>
            getTranslationOrUserText(
              $w(ComponentIds.Description).text,
              localeKeys.donationForm.description.defaultText(),
            ),
        },
        [ComponentIds.MultiStateBox]: {
          currentState: () =>
            rootStore.$state.shouldShowEmptyState
              ? StateIds.Empty
              : StateIds.Form,
        },
        [ComponentIds.RootBox]: {
          deleted: () =>
            flowAPI.environment.isViewer &&
            rootStore.$state.shouldShowEmptyState,
        },
        [ComponentIds.PremiumError]: {
          deleted: () => !rootStore.$state.showPremiumError,
        },
        [ComponentIds.PremiumErrorText]: {
          text: () => getErrorText(flowAPI, localeKeys),
        },
      });
    },
    exports: {},
  };
});

const getErrorText = (
  flowAPI: PlatformControllerFlowAPI,
  localeKeys: ILocaleKeys,
): string => {
  if (flowAPI.environment.isPreview) {
    return localeKeys.donationForm.donate.button.previewNotification();
  }

  return flowAPI.environment.isViewer
    ? localeKeys.donationForm.donate.button.notPremium.errorMessage()
    : localeKeys.donationForm.donate.button.notPremium.editorNotification();
};
