import { BindParams, RootStore } from '@/components/DonationForm/types';
import { ComponentIds as Ids } from '@/components/DonationForm/constants';

const bindComment = (rootStore: RootStore): BindParams => {
  const { $state, $w } = rootStore;

  return {
    [Ids.Comment]: {
      deleted: () => !$state.isCommentEnabled,
      label: () => $w(Ids.Comment).label,
    },
  };
};

export default bindComment;
