import { IHttpClient } from '@wix/yoshi-flow-editor';

export type Currency = {
  code: string;
  symbol: string;
};

export const getCurrencies = async (
  httpClient: IHttpClient,
): Promise<Currency[]> => {
  const url = '/_api/currency-converter/v1/currencies';
  return httpClient
    .request<{ currencies: Currency[] }>({
      method: 'GET',
      url,
    })
    .then(({ data }) => data.currencies)
    .catch((e) => {
      console.error(`Failed to get currencies`, e);
      return [];
    });
};
