import {
  changeEventOrigin,
  ComponentIds as Ids,
} from '@/components/AmountWidget/constants';
import { BindParams, AmountStore } from '@/components/AmountWidget/types';
import { runInAction } from 'mobx';

export const bindCustomAmountField = ({
  fireChangeEvent,
  clearInputValidity,
  $w,
  $widgetState,
  localeKeys,
}: AmountStore): BindParams => {
  const fieldInput = $w(Ids.CustomAmountFieldInput);
  const $customAmount = $widgetState.customAmount;

  const updateStateValue = (value: string) => {
    $customAmount.value = value;
    if (value) {
      $customAmount.isDirty = true;
    }
  };

  const onInputChange: $w.EventHandler = (e) => {
    updateStateValue(e.target.value);
    if ($customAmount.hasError) {
      fireChangeEvent(changeEventOrigin.CustomAmountField);
    } else {
      fireChangeEvent(
        changeEventOrigin.CustomAmountField,
        e.target.value ?? undefined,
      );
      clearInputValidity();
    }
  };

  const validator: $w.Validator = (value, reject) => {
    runInAction(() => updateStateValue(value as string));
    if ($customAmount.hasError) {
      reject($customAmount.errorMessage);
    }
  };

  const onBlur = () => {
    if (!$customAmount.hasError) {
      clearInputValidity();
    }
  };

  const onKeyPress = () => {
    $customAmount.isDirty = true;
  };

  fieldInput.onCustomValidation(validator, true);
  return {
    [Ids.CustomAmountField]: {
      deleted: () => !$widgetState.customAmount.showInput,
    },
    [Ids.CustomAmountFieldInput]: {
      prefix: () => $widgetState.currencySymbol,
      onChange: onInputChange,
      onInput: onInputChange,
      onBlur,
      onKeyPress,
      required: () => true,
      placeholder: () =>
        ` ${localeKeys.donationForm.otherAmount.input.placeholder()}`,
      accessibility: {
        ariaAttributes: {
          label: () =>
            localeKeys.donationForm.sr.otherAmount.label({
              currency: $widgetState.currencySymbol,
            }),
          describedBy: () =>
            $customAmount.hasError ? $w(Ids.ErrorText) : (undefined as any),
        },
      },
    },
  };
};

export default bindCustomAmountField;
