import { SubscriptionFrequency } from '@wix/ambassador-ecom-v1-order/types';
import { ILocaleKeys } from '@/locale-keys';

export const getSubscriptionFrequencyTranslation = (
  frequency: SubscriptionFrequency,
  localeKeys: ILocaleKeys,
): string => {
  switch (frequency) {
    case SubscriptionFrequency.WEEK:
      return localeKeys.thankYouPage.duration.weekly();
    case SubscriptionFrequency.MONTH:
      return localeKeys.thankYouPage.duration.monthly();
    case SubscriptionFrequency.YEAR:
      return localeKeys.thankYouPage.duration.yearly();
    case SubscriptionFrequency.UNDEFINED:
    case undefined:
    default:
      return localeKeys.thankYouPage.duration.oneTime();
  }
};
