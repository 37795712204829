import { ComponentIds as Ids } from '@/components/FrequencyWidget/constants';
import { FrequencyOption } from '@/common/types';
import { BindParams, FrequencyStore } from '@/components/FrequencyWidget/types';
import { itemAutoSelect } from '@/common/utils/repeater';
import { MsbItemState } from '@/components/AmountWidget/constants';

const toData = (values: FrequencyOption[]) =>
  values.map(({ value, label }, index) => ({
    _id: `frequency-${value.replace('_', '-')}`,
    value,
    label: label || ' ',
    index,
  }));

export const bindOptionsList = ({
  $props,
  $w,
  fireChangeEvent,
  $widgetState,
}: FrequencyStore): BindParams => {
  const disposeMap = new Map<string, () => void>();
  return {
    [Ids.Repeater]: {
      data: () => toData($props.options),
      item: ({ label, _id, value }, _, currentIndex) => {
        const dispose = itemAutoSelect(
          currentIndex,
          $w(Ids.Repeater),
          $widgetState,
        );
        disposeMap.set(_id, dispose);
        return {
          [Ids.RepeaterItem]: {
            inputLabel: () => label,
          },
          [Ids.multiStateItem]: {
            currentState: () => {
              return value === $widgetState.value
                ? MsbItemState.Selected
                : MsbItemState.Default;
            },
          },
          [Ids.itemTextDefault]: {
            text: () => label,
          },
          [Ids.itemTextSelected]: {
            text: () => label,
          },
        };
      },
      onItemSelected: (_, { value }) => {
        fireChangeEvent(value);
      },
      onItemRemoved: (item) => {
        try {
          const dispose = disposeMap.get(item?._id);
          if (dispose) {
            dispose();
            disposeMap.delete(item?._id);
          }
        } catch (e) {
          console.error(e);
        }
      },
    } as any,
  };
};

export default bindOptionsList;
