import model from './model';
import { getTYPInitialData } from '@/components/ThankYouPage/services/getTypInitialData';
import { ComponentIds } from '@/components/ThankYouPage/constants';
import { initLocaleKeys } from '@/common/utils/locale';
import {
  getTypTranslationKeys,
  ITypTranslationKeys,
} from '@/components/ThankYouPage/services/getTypTranslations';
import { reportThankYouPageLoad } from '@/components/ThankYouPage/services/bi';

export default model.createController(
  ({ $w, $bind, $widget, flowAPI, controllerConfig }) => {
    const localeKeys = initLocaleKeys(flowAPI.translations.i18n);

    return {
      pageReady: async () => {
        const initialData = await getTYPInitialData(
          controllerConfig,
          flowAPI.httpClient,
        );

        const keys: ITypTranslationKeys = getTypTranslationKeys(
          flowAPI,
          controllerConfig.wixCodeApi,
          localeKeys,
          initialData,
        );

        $w(ComponentIds.typTitle).text = keys.title;
        $w(ComponentIds.typLine2).text = keys.line2;
        $w(ComponentIds.typLine3).text = keys.line3;

        if (initialData?.id) {
          reportThankYouPageLoad(flowAPI, initialData);
        }
      },
      exports: {},
    };
  },
);
