import { I$W } from '@wix/yoshi-flow-editor';
import { DonationCampaign } from '@wix/ambassador-donations-v1-donation-campaign/types';
import { ComponentIds } from '@/components/DonationForm/constants';
import { viewDonationsWidgetInSf } from '@wix/bi-logger-wixdonations/v2';

export const reportWidgetLoad = (
  $w: I$W,
  flowAPI: any,
  campaign: DonationCampaign,
) => {
  flowAPI.bi!.report(
    viewDonationsWidgetInSf({
      campaignId: campaign.id ?? undefined,
      availableFrequency:
        campaign.recurringDonation?.frequencies?.toString() ?? undefined,
      hasTitle: !$w(ComponentIds.Title).collapsed,
      hasDescription: !$w(ComponentIds.Description).collapsed,
      hasDonorsSuggestedAmount: campaign.suggestedAmounts?.enabled,
      hasAmountByDonor: campaign.customAmount?.enabled,
      hasNotesFromDonor: !$w(ComponentIds.Comment).collapsed,
    }),
  );
};
