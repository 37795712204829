import model from './model';
import { ComponentIds as Ids } from '@/components/NoteWidget/constants';
import { makeAutoObservable } from 'mobx';
import { getTranslationOrUserText } from '@/common/utils/getTranslationOrUserText';
import { initLocaleKeys } from '@/common/utils/locale';

export default model.createController(
  ({ $props, $bindAll, $widget, $w, flowAPI }) => {
    const $widgetState = makeAutoObservable({
      value: $w(Ids.TextBox).value ?? '',
      get charCount() {
        return this.value.length;
      },
      reset: () => {
        $widgetState.value = '';
      },
    });
    return {
      pageReady: async () => {
        const localeKeys = initLocaleKeys(flowAPI.translations.i18n);
        const fireChangeEvent = (value: string) => {
          $widget.fireEvent('change', value);
        };
        const onInputChange = (e: $w.Event) => {
          const newValue = e.target.value;
          fireChangeEvent(newValue);
          $widgetState.value = newValue;
        };
        $bindAll({
          [Ids.TextBox]: {
            maxLength: () => $props.maxLength,
            onChange: onInputChange,
            onInput: onInputChange,
            accessibility: {
              ariaAttributes: {
                labelledBy: () => $w(Ids.Label),
              },
            },
            required: () => false,
            placeholder: () => $props.placeholder,
          },
          [Ids.Label]: {
            deleted: () => !$props.shouldShowTitle,
            text: () =>
              getTranslationOrUserText(
                $w(Ids.Label).text,
                localeKeys.donationForm.note.title.defaultText(),
                $props.label,
              ),
            onClick: () => $w(Ids.TextBox).focus(),
          },
          [Ids.CharCounter]: {
            text: () => `${$widgetState.charCount}/${$props.maxLength}`,
          },
        });
      },
      exports: {
        focus: () => $w(Ids.TextBox).focus(),
        isValid: () => $w(Ids.TextBox).validity.valid,
        getValue: () => $w(Ids.TextBox).value,
        reset: () => {
          $w(Ids.TextBox).value = '';
          $widgetState?.reset();
        },
      },
    };
  },
);
