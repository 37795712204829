import { BindParams, RootStore } from '@/components/DonationForm/types';
import { ComponentIds as Ids } from '@/components/DonationForm/constants';
import { makeAutoObservable } from 'mobx';
import { suggestedAmountToOption } from '@/common/utils/suggestedAmountToOptions';
import { CustomAmountItem } from '@/components/AmountWidget/constants';
import { initLocaleKeys } from '@/common/utils/locale';

const bindAmount = (rootStore: RootStore): BindParams => {
  const { $state, localeKeys } = rootStore;

  const amountState = makeAutoObservable({
    get options() {
      if ($state.isSuggestedAmountEnabled) {
        const options = $state.campaign!.suggestedAmounts!.amounts!.map(
          suggestedAmountToOption,
        );

        if ($state.isCustomAmountEnabled) {
          return [...options, getOtherAmountOption(localeKeys)];
        }

        return options;
      }
      return [];
    },
    get minCustomAmount() {
      return $state.campaign?.customAmount?.minAmount;
    },
    get maxCustomAmount() {
      return $state.campaign?.customAmount?.maxAmount;
    },
  });

  return {
    [Ids.Amount]: {
      options: () => amountState.options,
      customAmountEnabled: () => $state.isCustomAmountEnabled,
      defaultValue: () => $state.amountDefaultValue,
      minCustomAmount: () => amountState.minCustomAmount,
      maxCustomAmount: () => amountState.maxCustomAmount,
    },
  };
};

const getOtherAmountOption = (
  localeKeys: ReturnType<typeof initLocaleKeys>,
) => ({
  value: CustomAmountItem,
  label: localeKeys.donationForm.otherAmount.button.defaultText(),
});

export default bindAmount;
