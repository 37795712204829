import { RootStore } from '@/components/DonationForm/types';
import { ComponentIds } from '@/components/DonationForm/constants';

export const validate = (rootStore: RootStore) => {
  const { $w } = rootStore;
  if (!$w(ComponentIds.Amount).isValid()) {
    $w(ComponentIds.Amount).focus();
    return false;
  } else if (!$w(ComponentIds.Frequency).isValid()) {
    $w(ComponentIds.Frequency).focus();
    return false;
  } else if (!$w(ComponentIds.Comment).isValid()) {
    $w(ComponentIds.Comment).focus();
    return false;
  } else if (!rootStore.$state.isPremium) {
    rootStore.$state.showPremiumError = true;
    return false;
  }
  return true;
};

export const getUserInput = (rootStore: RootStore) => {
  const { $w } = rootStore;
  const amount = $w(ComponentIds.Amount).getValue();
  const frequency = $w(ComponentIds.Frequency).getValue();
  const comment = $w(ComponentIds.Comment).getValue();
  return { amount, frequency, comment };
};
