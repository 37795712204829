import { BindParams, AmountStore } from '@/components/AmountWidget/types';
import Field from './Field/Field.bind';
import Error from './Error/Error.bind';

export const bindCustomAmount = (amountStore: AmountStore): BindParams => {
  return {
    ...Field(amountStore),
    ...Error(amountStore),
  };
};

export default bindCustomAmount;
