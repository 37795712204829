import { IHttpClient } from '@wix/yoshi-flow-editor';

export const getIsPremium = async (
  httpClient: IHttpClient,
): Promise<boolean> => {
  const url = '/_serverless/wix-donations-catalog/api/v1/is-premium';
  return httpClient
    .request<{ isPremium: boolean }>({
      method: 'GET',
      url,
    })
    .then(({ data }) => {
      return data.isPremium;
    })
    .catch((e) => {
      console.error(`Failed to get premium status`, e);
      // we are getting 405 for old sites with premium. in this case assume TRUE for now
      return true;
    });
};
