import { IHttpClient } from '@wix/yoshi-flow-editor';
import { queryCampaigns } from '@/common/api/queryCampaigns';
import { DonationCampaign } from '@wix/ambassador-donations-v1-donation-campaign/types';

export const DEFAULT_CAMPAIGNS = [
  '77729a42-e6cb-46c4-9701-6a724ad00a71',
  '41104d43-e1b1-4473-8968-31e38ffb6591',
  'e1628868-4c78-454d-84f9-634a6989fd9d',
  // from templates for translations
  // https://docs.google.com/spreadsheets/d/1aUE0UfdGf9X4Iu0JOaHFt4xCahflYnd52wpYnoK67-E/edit?gid=1889125073#gid=1889125073
];

export const isDefaultCampaign = (campaignId?: string | null): boolean => {
  if (!campaignId) {
    return false;
  }
  return DEFAULT_CAMPAIGNS.includes(campaignId);
};

export const getDefaultCampaign = (
  httpClient: IHttpClient,
): Promise<DonationCampaign | undefined> => {
  return queryCampaigns(httpClient).then((list) =>
    list.length === 1 && isDefaultCampaign(list[0].id) ? list[0] : undefined,
  );
};
