import { IHttpClient } from '@wix/yoshi-flow-editor';
import { Subscription } from '@wix/ambassador-stores-v1-subscription/types';
import { getSubscription } from '@wix/ambassador-stores-v1-subscription/http';

export const getSubscriptionById = async (
  subscriptionId: string,
  httpClient: IHttpClient,
): Promise<Subscription> => {
  return httpClient
    .request(getSubscription({ id: subscriptionId }))
    .then((res) => res.data.subscription ?? {})
    .catch((e) => {
      console.log('Failed to get subscription', e);
      return {};
    });
};
