import { FrequencyOption } from '@/common/types';
import { Frequency } from '@wix/ambassador-donations-v1-donation-campaign/types';

export const frequenciesToOptions = (
  frequency: Frequency,
  localeKeys,
): FrequencyOption => ({
  value: frequency,
  label: getFrequencyLabel(frequency, localeKeys),
});

const getFrequencyLabel = (frequency: Frequency, localeKeys) => {
  switch (frequency) {
    case Frequency.ONE_TIME:
      return localeKeys.donationForm.frequency.oneTime.option();
    case Frequency.MONTH:
      return localeKeys.donationForm.frequency.monthly.option();
    case Frequency.YEAR:
      return localeKeys.donationForm.frequency.yearly.option();
    case Frequency.WEEK:
      return localeKeys.donationForm.frequency.weekly.option();
    default:
      return '';
  }
};
