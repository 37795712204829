import { makeAutoObservable } from 'mobx';
import { ControllerParams, RootStore } from '@/components/DonationForm/types';
import { DonationCampaign } from '@wix/ambassador-donations-v1-donation-campaign/types';
import { ComponentIds as Ids } from '@/components/DonationForm/constants';

export interface FormState {
  frequency: string;
  comment: string;
  amount: number;
  reset(): void;
}

export class RootState {
  public campaign: DonationCampaign | undefined;
  public error: string | undefined;
  public isPremium: boolean;
  public showPremiumError: boolean;

  constructor(
    private readonly rootStore: Omit<RootStore, '$state'>,
    private readonly $props: ControllerParams['$props'],
    private readonly widgetProps: ControllerParams['$widget']['props'],
  ) {
    this.campaign = undefined;
    this.error = undefined;
    this.isPremium = false;
    this.showPremiumError = false;
    makeAutoObservable(this);
  }

  public reset(
    campaign?: DonationCampaign,
    isViewer: boolean = false,
    isPremium = false,
  ) {
    this.campaign = campaign;
    this.isPremium = isPremium;
    this.rootStore.$w(Ids.Amount)?.reset();
    this.rootStore.$w(Ids.Frequency)?.reset();
    this.rootStore.$w(Ids.Comment)?.reset();
    this.error = undefined;

    if (!isViewer && !this.isPremium) {
      this.showPremiumError = true;
    }
  }

  public get campaignId() {
    return this.$props.campaignId ?? this.widgetProps.campaignId;
  }

  public get isArchived() {
    return !!(this.campaign && this.campaign.active === false);
  }

  public get amountDefaultValue() {
    const defaultValueFromSettings =
      this.$props.amountDefaultValue ?? this.widgetProps.amountDefaultValue;

    const doesDefaultValueExists =
      !!this.campaign?.suggestedAmounts?.amounts?.find(
        (amount) => amount?.amount?.amount === defaultValueFromSettings,
      );

    return doesDefaultValueExists
      ? defaultValueFromSettings
      : this.campaign?.suggestedAmounts?.amounts?.[0]?.amount?.amount || '';
  }

  public get frequencyDefaultValue() {
    const defaultValueFromSettings =
      this.$props.frequencyDefaultValue ??
      this.widgetProps.frequencyDefaultValue;

    const doesDefaultValueExists =
      !!this.campaign?.recurringDonation?.frequencies?.find(
        (value) => value === defaultValueFromSettings,
      );

    return doesDefaultValueExists
      ? defaultValueFromSettings
      : this.campaign?.recurringDonation?.frequencies?.[0] || '';
  }

  public get isNotFound() {
    return !this.campaign;
  }

  public get shouldShowEmptyState() {
    return this.isArchived || this.isNotFound;
  }

  get isSuggestedAmountEnabled() {
    return !!this.campaign?.suggestedAmounts?.enabled;
  }

  get isCustomAmountEnabled() {
    return !!this.campaign?.customAmount?.enabled;
  }

  get isFrequencyEnabled() {
    return !!this.campaign?.recurringDonation?.enabled;
  }

  get isCommentEnabled() {
    return !!this.campaign?.enableComments;
  }
}
