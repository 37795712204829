import { donationThankYouPageLoadSf } from '@wix/bi-logger-wixdonations/v2';
import { TypInitialData } from '@/components/ThankYouPage/constants';

export const reportThankYouPageLoad = (flowAPI, data: TypInitialData) => {
  flowAPI.bi!.report(
    donationThankYouPageLoadSf({
      campaignId: data.campaignId ?? undefined,
      orderId: data.id ?? undefined,
      checkoutId: data.checkoutId ?? undefined,
      paymentStatus: data.paymentStatus ?? undefined,
      catalogAppId: data.catalogAppId ?? undefined,
    }),
  );
};
