import { autorun } from 'mobx';

export const repeaterSelectionController =
  (
    $observable: { selectedIndex: number },
    repeater: $w.Repeater,
    whenClear?: () => boolean,
  ) =>
  () => {
    const shouldClear = whenClear?.() ?? false;
    const indexToSelect = shouldClear ? -1 : $observable.selectedIndex;
    const alreadySelected = repeater.selectedIndex === indexToSelect;
    /* istanbul ignore else reason: optimization for not set index twice */
    if (!alreadySelected) {
      /* istanbul ignore else reason: optimization for not setting -1 as index */
      if (indexToSelect !== -1) {
        repeater.selectedIndex = indexToSelect;
      } else {
        repeater.clearSelection();
      }
    }
  };

export const itemAutoSelect = (
  itemIndex: number,
  repeater: $w.Repeater,
  observable: { selectedIndex: number },
) => {
  return autorun(() => {
    const indexToSelect = observable.selectedIndex;
    const shouldBeSelected = indexToSelect === itemIndex;
    const isCurrentlySelected = repeater.selectedIndex === indexToSelect;
    if (shouldBeSelected && !isCurrentlySelected) {
      repeater.selectedIndex = itemIndex;
    }
  });
};
