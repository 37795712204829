const isDefaultEnglishLabel = (label) =>
  defaultEnglishLabels.filter((elem) => elem === label).length > 0;

export const getTranslationOrUserText = (label, translation, override?) => {
  if (override && !isDefaultEnglishLabel(override)) {
    return override;
  }

  if (!label || isDefaultEnglishLabel(label)) {
    return translation;
  }

  return label;
};

const defaultEnglishLabels = [
  'Amount',
  'Frequency',
  'Donate',
  'Comment (optional)',
  'Make a donation',
  'This is your campaign description. It’s a great place to tell visitors what this campaign is about, connect with your donors and draw attention to your cause.',
];
