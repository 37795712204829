import { ComponentIds as Ids } from '@/components/AmountWidget/constants';
import { BindParams, AmountStore } from '@/components/AmountWidget/types';

export const bindError = ({ $widgetState }: AmountStore): BindParams => {
  return {
    [Ids.ErrorOuterBox]: {
      deleted: () =>
        !$widgetState.customAmount.showInput ||
        !$widgetState.customAmount.hasError,
    },
    [Ids.ErrorText]: {
      text: () => $widgetState.customAmount.errorMessage,
    },
  };
};

export default bindError;
