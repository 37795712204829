import { BindParams, RootStore } from '@/components/DonationForm/types';
import { ComponentIds as Ids } from '@/components/DonationForm/constants';
import { handleDonateClickWrapper } from '@/components/DonationForm/services/handleDonateClick';
import { getTranslationOrUserText } from '@/common/utils/getTranslationOrUserText';

const bindDonateButton = (rootStore: RootStore): BindParams => {
  const { localeKeys, $w } = rootStore;
  return {
    [Ids.DonateButton]: {
      label: () =>
        getTranslationOrUserText(
          $w(Ids.DonateButton).label,
          localeKeys.donationForm.donate.button.defaultText(),
        ),
      disabled: () => false,
      onClick: async () => handleDonateClickWrapper(rootStore),
    },
  };
};

export default bindDonateButton;
