import { createCheckout } from '@wix/ambassador-ecom-v1-checkout/http';
import {
  ChannelType,
  Checkout,
  LineItem,
} from '@wix/ambassador-ecom-v1-checkout/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import { RootStore } from '@/components/DonationForm/types';

type DonationCheckoutParams = {
  donationLineItem: LineItem;
  comment: string;
  flowAPI: RootStore['controllerParams']['flowAPI'];
};

export const createDonationCheckout = async (
  httpClient: IHttpClient,
  params: DonationCheckoutParams,
): Promise<Checkout | undefined> => {
  const { donationLineItem, comment, flowAPI } = params;
  const hasComment = typeof comment === 'string' && comment.length > 0;
  return httpClient
    .request(
      createCheckout({
        checkoutInfo: {
          buyerNote: hasComment ? comment : undefined,
          customSettings: {
            lockGiftCard: true,
            lockCouponCode: true,
          },
        },
        lineItems: [donationLineItem],
        channelType: ChannelType.OTHER_PLATFORM,
      }),
    )
    .then((res) => res.data.checkout)
    .catch((e) => {
      flowAPI.reportError(new Error('Failed to create donation checkout', e));
      return undefined;
    });
};
