import { queryDonationCampaign } from '@wix/ambassador-donations-v1-donation-campaign/http';
import {
  DonationCampaign,
  SortOrder,
} from '@wix/ambassador-donations-v1-donation-campaign/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export const queryCampaigns = async (
  httpClient: IHttpClient,
): Promise<DonationCampaign[]> => {
  return httpClient
    .request(
      queryDonationCampaign({
        query: {
          filter: {
            active: true,
          },
          sort: [
            {
              fieldName: 'name',
              order: SortOrder.DESC,
            },
          ],
        },
      }),
    )
    .then((res) => res.data.donationCampaigns ?? [])
    .catch((e) => {
      console.log('Failed to query campaigns', e);
      return [];
    });
};
